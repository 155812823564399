import React, { useEffect, useState } from "react";
import BooksData from "../books.json";
import { useNavigate, useParams } from "react-router-dom";

export const BookDetailComponent = () => {
  const [detail, setDetails] = useState({});
  const { id } = useParams();
  useEffect(() => {
    const bookDetail = BooksData?.find((item) => {
      return parseInt(id) === item?.id;
    });
    setDetails(bookDetail);
  }, [id]);
  const navigate = useNavigate();
  return (
    <div className="container">
      <div className="mb-5 booking-detail mx-0">
        <div className="row mx-0 align-items-end justify-content-center">
          <div className="col-xl-4">
            <img src={`/${detail?.image}`} className="w-100" alt="" />
          </div>
          <div className="col-xxl-5 col-xl-8">
            <h2 className="mt-3">{detail?.title}</h2>
            <div className="d-flex align-items-center gap-3 my-4 author">
              <p className="mb-0">{detail?.author}</p>
              {detail?.published ? (
                <p className="pl-3 mb-0">July {detail?.published}</p>
              ) : (
                ""
              )}
            </div>
            <a target="_blank" href={`/${detail?.pdf}`} rel="noreferrer">
              <button
                className="btn btn-outline-primary py-3 px-5 w-75"
                style={{ borderRadius: "12px" }}
              >
                Open Book
              </button>
            </a>
            <button
              className="btn btn-primary px-5 py-3 mt-3 w-75"
              style={{ borderRadius: "12px" }}
              onClick={() => navigate("/home")}
            >
              Back to Home
            </button>
          </div>
          <div className="col-12 col-xl-8">
            <div className="ml-lg-5 pl-xl-5">
              <h3 className="text-white mt-5">Sinopsis</h3>
              <p className="mt-4">{detail?.longDescription}</p>
              <p>{detail?.description}</p>
              <div className="mt-5 d-flex gap-4">
                <div
                  className="btn btn-light text-primary fs-18"
                  style={{ borderRadius: "12px", width: "100px" }}
                >
                  English
                </div>
                <div
                  className="btn btn-light text-primary fs-18"
                  style={{ borderRadius: "12px", width: "100px" }}
                >
                  Literature
                </div>
                <div
                  className="btn btn-light text-primary fs-18"
                  style={{ borderRadius: "12px", width: "100px" }}
                >
                  AP Course
                </div>
              </div>
              <div>
                <h3 className="text-white mt-5 mb-3">Key Information</h3>

                <div className="row mx-0 px-0">
                  <div className="col-4 pl-0">
                    <p className="mb-1">Publisher</p>
                    <h6 className="text-white font-weight-light">
                      McGraw-Hill
                    </h6>
                    <p className="mt-3 mb-1">genre</p>
                    <h6 className="text-white font-weight-light">
                      Educational / AP Test Preparation
                    </h6>
                  </div>
                  <div className="col-4 px-2">
                    <p className="mb-1">Published date</p>
                    <h6 className="text-white font-weight-light">July 2005</h6>
                    <p className="mb-1 mt-3">Pages</p>
                    <h6 className="text-white font-weight-light">210</h6>
                  </div>
                  <div className="col-4 px-2">
                    <p className="mb-1">Language</p>
                    <h6 className="text-white font-weight-light">English</h6>
                    <p className="mb-1 mt-3">Grade Level</p>
                    <h6 className="text-white font-weight-light">
                      11th - 12th Grade
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
