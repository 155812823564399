import React from "react";
import useAuth from "../Hooks/useAuth";
import { Card } from "react-bootstrap";
import LogoutButton from "./LogoutButton";

const UserInfo = () => {
  const { user } = useAuth();

  return (
    <div className="user-info text-center">
      <div className="my-3">
        <img
          src={user?.reloadUserInfo?.photoUrl}
          className="rounded-circle m-auto"
          height="120px"
          width="120px"
          alt="profile-img"
        />
      </div>
      <div className="row mx-0">
        <div className="col-xl-8 m-auto">
          <Card className="ml-0 w-100 mt-4">
            <Card.Body className="text-left">
              <label htmlFor="" className="text-secondary mb-1">
                Your Name
              </label>
              <p className="mb-0 text-dark">
                {user?.reloadUserInfo?.displayName}
              </p>
              <label htmlFor="" className="mt-3 text-secondary mb-1">
                Email
              </label>
              <p className="mb-0">{user?.reloadUserInfo?.email}</p>
            </Card.Body>
          </Card>
          <div className="w-100 mt-3">
            <LogoutButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
