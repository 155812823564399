import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AlertWrapper from "./Alert/AlertWrapper";
import Alert from "./Alert/Alert";
import useAuth from "../Hooks/useAuth";

export const SignUpComponent = () => {
  const { signup, login } = useAuth();

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [viewPassword, setViewPassword] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signup(email, password, first_name, last_name);
      setSuccessMessage("User signed up");
      navigate("/home");
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(error);
      setErrorMessage("Error signing up:", errorCode, errorMessage);
    }
  };

  return (
    <>
      <AlertWrapper>
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>
      <div className="text-center w-100 px-lg-5 mt-4">
        <button
          type="button"
          onClick={login}
          style={{
            height: "55px",
            borderRadius: "50px",
            maxWidth: "100%",
          }}
          className="firebaseui-idp-button text-center bg-transparent shadow-none border mdl-button w-100 mdl-js-button mdl-button--raised firebaseui-idp-google firebaseui-id-idp-button"
          data-provider-id="google.com"
          data-upgraded=",MaterialButton"
        >
          <span className="firebaseui-idp-icon-wrapper">
            <img
              className="firebaseui-idp-icon"
              src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
            />
          </span>
          <span className="firebaseui-idp-text firebaseui-idp-text-long text-uppercase">
            Sign up with Google
          </span>
          <span className="firebaseui-idp-text firebaseui-idp-text-short text-uppercase">
            Google
          </span>
        </button>
      </div>
      <div className="divide">OR</div>
      <div className="mt-4">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-6 px-1">
              <div className="mb-3">
                <label htmlFor="firstName" className="text-secondary fs-13">
                  First name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  required
                  value={first_name}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-6 px-1">
              <div className="mb-3">
                <label htmlFor="lastName" className="text-secondary fs-13">
                  Last name
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  required
                  value={last_name}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="text-secondary fs-13">
              Email Address
            </label>
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <div className="d-flex align-items-center justify-content-between">
              <label htmlFor="password" className="text-secondary fs-13">
                Password
              </label>
              <span className="text-secondary fs-13 cursor-pointer">
                {viewPassword ? (
                  <div onClick={() => setViewPassword(false)}>
                    <i className="fa fa-eye"></i> Hide
                  </div>
                ) : (
                  <div onClick={() => setViewPassword(true)}>
                    <i class="fas fa-eye-slash"></i> Show
                  </div>
                )}
              </span>
            </div>
            <input
              type={viewPassword ? "text" : "password"}
              className="form-control"
              placeholder="Password"
              value={password}
              required
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <button
            type="submit"
            style={{ height: "60px", borderRadius: "50px" }}
            className="btn btn-primary btn-block mt-3"
          >
            SIGN UP
          </button>
        </form>
      </div>
    </>
  );
};
