// src/firebase.js
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDK2fN9srpEEo6S20hnj4wi0BYUibzU8TI",
  authDomain: "directorizebooks.firebaseapp.com",
  projectId: "directorizebooks",
  storageBucket: "directorizebooks.appspot.com",
  messagingSenderId: "925321106213",
  appId: "1:925321106213:web:66863789d7d88eabb100cc",
  measurementId: "G-C7FEZBFVMZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export {
  auth,
  provider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
};
