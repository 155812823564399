/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { BooksContext } from "../Hooks/useBook";
import { Container, Form, Nav, Navbar, Offcanvas } from "react-bootstrap";

export const Header = () => {
  const { handleSearch } = useContext(BooksContext);
  const [showDropdown, setShowDropdown] = useState(false);
  const location = useLocation();
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const params = new URLSearchParams(location.search);
  const term = params.get("search") || "";
  const navigate = useNavigate();
  return (
    <>
      <Navbar key="xl" expand={"xl"} className="mb-3 header">
        <Container fluid>
          <Navbar.Brand href="#" className="logo">
            <NavLink to={"/home"}>
              <img src="/directorize.png" alt="logo" />
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand-xl}`}
            className="bg-white mr-3"
            onClick={() => setShowOffcanvas(!showOffcanvas)}
          />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-xl`}
            aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
            placement="end"
            show={showOffcanvas}
            onHide={() => setShowOffcanvas(false)}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xl}`}>
                Directorize
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end align-items-xl-center flex-grow-1 gap-xl-3 pe-3">
                <NavLink
                  exact
                  className="nav-link"
                  activeClassName="active"
                  to="/home"
                  onClick={() => setShowOffcanvas(false)}
                >
                  Home
                </NavLink>
                <NavLink
                  className="nav-link d-md-flex d-none align-items-center justify-content-between"
                  activeClassName={showDropdown ? "" : "active"}
                  to="/genres"
                  onClick={(e) => {
                    setShowDropdown(!showDropdown);
                  }}
                >
                  Genres{" "}
                  <i
                    className={`fa fa-arrow-${
                      showDropdown ? "up" : "down"
                    } d-md-none d-block`}
                  ></i>
                </NavLink>{" "}
                <Link
                  className="nav-link d-md-none d-flex align-items-center justify-content-between"
                  to="/genres"
                  onClick={(e) => {
                    setShowDropdown(!showDropdown);
                  }}
                >
                  Genres{" "}
                  <i
                    className={`fa fa-arrow-${
                      showDropdown ? "up" : "down"
                    } d-md-none d-block`}
                  ></i>
                </Link>{" "}
                {showDropdown && (
                  <div
                    className={`d-md-none d-block ${
                      showDropdown ? "opacity-1" : "opacity-0"
                    }`}
                  >
                    <a
                      className={`nav-link ${
                        term === "english" ? "active" : ""
                      }`}
                      onClick={() => {
                        const data = {
                          target: {
                            value: "english",
                          },
                        };
                        handleSearch(data);
                        setShowOffcanvas(false);
                        params.set("search", "english");
                        navigate({
                          pathname: "/genres",
                          search: params.toString(),
                        });
                      }}
                    >
                      English
                    </a>
                    <a
                      className={`nav-link ${term === "math" ? "active" : ""}`}
                      onClick={() => {
                        handleSearch({
                          target: {
                            value: "math",
                          },
                        });
                        params.set("search", "math");
                        setShowOffcanvas(false);
                        navigate({
                          pathname: "/genres",
                          search: params.toString(),
                        });
                      }}
                    >
                      Math
                    </a>
                    <a
                      className={`nav-link ${
                        term === "economics" ? "active" : ""
                      }`}
                      onClick={() => {
                        handleSearch({
                          target: {
                            value: "economics",
                          },
                        });
                        params.set("search", "economics");
                        setShowOffcanvas(false);
                        navigate({
                          pathname: "/genres",
                          search: params.toString(),
                        });
                      }}
                    >
                      Economics
                    </a>
                    <a
                      className={`nav-link ${
                        term === "biology" ? "active" : ""
                      }`}
                      onClick={() => {
                        handleSearch({
                          target: {
                            value: "biology",
                          },
                        });
                        params.set("search", "biology");
                        setShowOffcanvas(false);
                        navigate({
                          pathname: "/genres",
                          search: params.toString(),
                        });
                      }}
                    >
                      Biology
                    </a>
                    <a
                      className={`nav-link ${
                        term === "physics" ? "active" : ""
                      }`}
                      onClick={() => {
                        handleSearch({
                          target: {
                            value: "physics",
                          },
                        });
                        params.set("search", "physics");
                        setShowOffcanvas(false);
                        navigate({
                          pathname: "/genres",
                          search: params.toString(),
                        });
                      }}
                    >
                      Physics
                    </a>
                    <a
                      className={`nav-link ${
                        term === "chemistry" ? "active" : ""
                      }`}
                      onClick={() => {
                        handleSearch({
                          target: {
                            value: "chemistry",
                          },
                        });
                        setShowOffcanvas(false);
                        params.set("search", "chemistry");
                        navigate({
                          pathname: "/genres",
                          search: params.toString(),
                        });
                      }}
                    >
                      Chemistry
                    </a>
                  </div>
                )}
                <NavLink
                  activeClassName="active"
                  className="nav-link"
                  to="/profile"
                  onClick={() => setShowOffcanvas(false)}
                >
                  Profile
                </NavLink>{" "}
              </Nav>
              <Form className="d-flex">
                <div>
                  <div
                    className="input-group mb-3 mr-3"
                    style={{ borderRadius: "20px" }}
                  >
                    <div className="input-group-prepend">
                      <span className="input-group-text bg-white border-0">
                        <i class="fa fa-search"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control border-0"
                      style={{ height: "45px" }}
                      onChange={(e) => handleSearch(e)}
                      placeholder="Search Textbook"
                    />
                  </div>
                </div>
              </Form>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};
