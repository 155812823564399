/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useAuth from "../Hooks/useAuth";
import { Link } from "react-router-dom";
import BooksData from "../books.json";

export const HomeComponent = () => {
  const { user } = useAuth();
  const filteredBooks = BooksData;
  const [booksArray, setBooksArray] = useState([]);

  const getBooks = () => {
    const storedBooks = localStorage.getItem(`objectsArray_${user?.uid}`);
    if (storedBooks) {
      const parsedBooks = JSON.parse(storedBooks);
      setBooksArray(parsedBooks);
    } else {
      console.log("No books found in localStorage");
    }
  };

  // Function to delete a date from localStorag
  const deleteBookFromLocalStorage = (bookId) => {
    const updatedBooksArray = booksArray.filter((book) => book.id !== bookId);
    setBooksArray(updatedBooksArray);
    localStorage.setItem(
      `objectsArray_${user?.uid}`,
      JSON.stringify(updatedBooksArray)
    );
    getBooks();
  };
  useEffect(() => {
    if (user?.uid) {
      getBooks();
    }
  }, [user]);
  const addBookToLocalStorageArray = (book) => {
    const storedArray = localStorage.getItem(`objectsArray_${user?.uid}`);
    let updatedArray = [];
    if (storedArray) {
      updatedArray = JSON.parse(storedArray);
    }
    const bookExists = updatedArray.some((b) => b.id === book.id);
    if (bookExists) {
      console.log(`Book with ID ${book.id} already exists.`);
      return;
    }
    updatedArray.push(book);
    localStorage.setItem(
      `objectsArray_${user?.uid}`,
      JSON.stringify(updatedArray)
    );
    getBooks();
  };

  return (
    <section className="px-3 mt-5">
      <div className="home-section">
        <h1 className="font-weight-bold">
          Welcome Back ,{" "}
          {user?.reloadUserInfo?.displayName || user?.displayName}!{" "}
          <img src="/imgs/hand.png" width={80} alt="" />
        </h1>
      </div>
      {booksArray?.length > 0 && (
        <section>
          <h2 className="text-white mt-5 mb-5">Continue Reading:</h2>
          <div className="row">
            {booksArray?.length > 0 ? (
              booksArray?.map((book) => (
                <div className="col-xl-4 col-lg-6 mt-4" key={book.id}>
                  <div className="position-relative w-100">
                    <div
                      className="trash cursor-pointer"
                      onClick={() => deleteBookFromLocalStorage(book?.id)}
                    >
                      <i className="fa fa-trash text-danger fs-20"></i>
                    </div>
                    <div className="card-icon text-dark">
                      <Link to={`/book/${book?.id}`}>
                        <img
                          src={`/${book?.image}`}
                          className="w-100"
                          height={160}
                          alt={book?.title}
                        />
                      </Link>
                    </div>
                    <div className="card">
                      <div className="card-content">
                        <Link
                          to={`/book/${book?.id}`}
                          className="text-dark"
                          style={{ textDecoration: "none" }}
                        >
                          <h2 className="text-capitalize">{book?.title}</h2>
                          <p>
                            By{" "}
                            <span className="text-primary">{book?.author}</span>
                          </p>
                          {book?.published && (
                            <p>
                              Publish:{" "}
                              <span className="text-primary">
                                {book?.published}
                              </span>
                            </p>
                          )}
                          <p className="description">{book?.description}</p>
                        </Link>
                        <a
                          target="_blank"
                          href={book?.pdf}
                          // download={book?.pdf}
                          rel="noreferrer"
                        >
                          <button type="submit">Open</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <h4 className="text-center">No Data Found</h4>
            )}
          </div>
        </section>
      )}
      <section>
        <h2 className="text-white mt-5 mb-5">Recommended:</h2>
        <div className="row">
          {filteredBooks?.length > 0 ? (
            filteredBooks?.map((book) => (
              <div className="col-xl-4 col-lg-6 mt-4" key={book.id}>
                <div
                  className="position-relative w-100"
                  onClick={() => addBookToLocalStorageArray(book)}
                >
                  <div className="card-icon text-dark">
                    <Link to={`/book/${book?.id}`}>
                      <img
                        src={`/${book?.image}`}
                        className="w-100"
                        height={160}
                        alt={book?.title}
                      />
                    </Link>
                  </div>
                  <div className="card">
                    <div className="card-content">
                      <Link
                        to={`/book/${book?.id}`}
                        className="text-dark"
                        style={{ textDecoration: "none" }}
                      >
                        <h2 className="text-capitalize">{book?.title}</h2>
                        <p>
                          By{" "}
                          <span className="text-primary">{book?.author}</span>
                        </p>
                        {book?.published && (
                          <p>
                            Publish:{" "}
                            <span className="text-primary">
                              {book?.published}
                            </span>
                          </p>
                        )}
                        <p className="description">{book?.description}</p>
                      </Link>
                      <a
                        target="_blank"
                        href={book?.pdf}
                        // download={book?.pdf}
                        rel="noreferrer"
                      >
                        <button type="submit">Open</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <h4 className="text-center">No Data Found</h4>
          )}
        </div>
      </section>
    </section>
  );
};
