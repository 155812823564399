import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { BooksContext } from "../Hooks/useBook";
import useAuth from "../Hooks/useAuth";

export const GenresComponent = () => {
  const { user } = useAuth();

  const { filteredBooks } = useContext(BooksContext);

  const addBookToLocalStorageArray = (book) => {
    const storedArray = localStorage.getItem(`objectsArray_${user?.uid}`);
    let updatedArray = [];
    if (storedArray) {
      updatedArray = JSON.parse(storedArray);
    }
    updatedArray.push(book);
    localStorage.setItem(
      `objectsArray_${user?.uid}`,
      JSON.stringify(updatedArray)
    );
  };

  return (
    <div>
      <div className="row">
        {filteredBooks?.length > 0 ? (
          filteredBooks?.map((book) => (
            <div className="col-xl-4 col-lg-6 mt-4" key={book.id}>
              <div
                className="position-relative w-100"
                onClick={() => addBookToLocalStorageArray(book)}
              >
                <div className="card-icon text-dark">
                  <Link to={`/book/${book?.id}`}>
                    <img
                      src={`/${book?.image}`}
                      className="w-100"
                      height={160}
                      alt={book?.title}
                    />
                  </Link>
                </div>
                <div className="card">
                  <div className="card-content">
                    <Link
                      to={`/book/${book?.id}`}
                      className="text-dark"
                      style={{ textDecoration: "none" }}
                    >
                      <h2 className="text-capitalize">{book?.title}</h2>
                      <p>
                        By <span className="text-primary">{book?.author}</span>
                      </p>
                      {book?.published && (
                        <p>
                          Publish:{" "}
                          <span className="text-primary">
                            {book?.published}
                          </span>
                        </p>
                      )}
                      <p className="description">{book?.description}</p>
                    </Link>
                    <a target="_blank" href={`/${book?.pdf}`} rel="noreferrer">
                      <button type="submit">Open</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <h4 className="text-center">No Data Found</h4>
        )}
      </div>
    </div>
  );
};
