import { Helmet } from "react-helmet";
import { HomeComponent } from "../Components/Home";

const Home = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Directorize</title>
        <meta
          name="description"
          content="Directorize is an exclusive online textbook directory for ASD students, offering easy access to all your school textbooks in one place. Browse by category, view key details, and read instantly with a seamless and secure experience tailored just for you."
        />
        <link rel="canonical" href="https://directorize.education/" />
        <link
          id="favicon"
          rel="icon"
          type="image/svg"
          href="/favicon.svg"
          sizes="16x16"
          data-react-helmet="true"
        />
        <title>Directorize</title>
        <meta property="og:title" content="Directorize" />
        <meta
          property="og:description"
          content="Directorize is an exclusive online textbook directory for ASD students, offering easy access to all your school textbooks in one place. Browse by category, view key details, and read instantly with a seamless and secure experience tailored just for you."
        />
        <meta
          property="og:image"
          content="https://directorize.education/favicon.png"
        />
        <meta property="og:url" content="https://directorize.education/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Directorize" />
        <meta
          name="twitter:description"
          content="Find and access educational resources easily with Directorize."
        />
        <meta
          name="twitter:image"
          content="https://directorize.education/directorize-blue-logo.svg"
        />
      </Helmet>
      <HomeComponent />
    </>
  );
};
export default Home;
