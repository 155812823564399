import React from "react";
import UserInfo from "../Components/UserInfo";

const Profile = () => {
  return (
    <div className="profile-container">
      <div>
        <UserInfo />
      </div>
    </div>
  );
};

export default Profile;
