import { Outlet, useLocation } from "react-router-dom";
import React from "react";
import { Header } from "./Components/Header";
import Sidebar from "./Components/Sidebar";

const Layout = () => {
  const location = useLocation();
  return (
    <>
      <Header />
      <div className="d-flex">
        {location?.pathname.includes("genres") && <Sidebar />}
        <main
          id="content"
          role="main"
          className="main splitted-content-main px-md-5 px-3 w-100"
        >
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default Layout;
