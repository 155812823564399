import React from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../Hooks/useAuth";

const LogoutButton = ({
  CustomClass = "btn btn-outline-primary w-100 text-left",
}) => {
  const { logout } = useAuth();

  const navigate = useNavigate();
  const handleLogout = () => {
    logout();
    navigate("/");
  };
  return (
    <div className="logout-button">
      <button className={CustomClass} onClick={handleLogout}>
        <i className="fas fa-sign-out-alt"></i> Sign Out
      </button>
    </div>
  );
};

export default LogoutButton;
