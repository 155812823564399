/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import AlertWrapper from "./Alert/AlertWrapper";
import Alert from "./Alert/Alert";
import useAuth from "../Hooks/useAuth";
import { Link, useNavigate } from "react-router-dom";
import { Tabs, Tab, Spinner } from "react-bootstrap";
import { SignUpComponent } from "./Signup";

export const LoginComponent = () => {
  const { user, login, loginManual } = useAuth();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [viewPassword, setViewPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const handleLogin = () => {
    login();
    //
  };

  useEffect(() => {
    if (user) {
      navigate("/home");
      setSuccessMessage("Successfully logged in.");
    }
  }, [user]);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    try {
      await loginManual(email, password);
      setSuccessMessage("User logged in");
      setIsLoading(false);
    } catch (error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      setErrorMessage("Error logging in:", errorCode, errorMessage);
    } finally {
    }
  };

  return (
    <>
      <AlertWrapper>
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />{" "}
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>
      <div
        className="container d-flex align-items-center justify-content-center"
        style={{ height: "100vh" }}
      >
        <div className="row w-100">
          <div
            className="col-xl-6 m-auto bg-white user-tabs p-md-5 p-4"
            style={{ borderRadius: "15px" }}
          >
            <Tabs
              defaultActiveKey="login"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="signup" title="Sign Up">
                <SignUpComponent />
              </Tab>
              <Tab eventKey="login" title="Login">
                <div>
                  <div className="p-lg-5">
                    <form>
                      <div className="mb-3">
                        <label htmlFor="email" className="text-secondary fs-13">
                          Email Address
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          value={email}
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="mb-1">
                        <div className="d-flex align-items-center justify-content-between">
                          <label
                            htmlFor="password"
                            className="text-secondary fs-13"
                          >
                            Password
                          </label>
                          <span className="text-secondary fs-13 cursor-pointer">
                            {viewPassword ? (
                              <div onClick={() => setViewPassword(false)}>
                                <i className="fa fa-eye"></i> Hide
                              </div>
                            ) : (
                              <div onClick={() => setViewPassword(true)}>
                                <i class="fas fa-eye-slash"></i> Show
                              </div>
                            )}
                          </span>
                        </div>
                        <input
                          type={viewPassword ? "text" : "password"}
                          className="form-control"
                          placeholder="Password"
                          value={password}
                          required
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>

                      <div className="message">
                        <div>
                          <Link
                            className="nav-link"
                            style={{ textDecoration: "underline" }}
                            to="/forget"
                          >
                            Forget Password
                          </Link>
                        </div>
                      </div>
                      <button
                        type="button"
                        onClick={handleSubmit}
                        style={{ height: "60px", borderRadius: "50px" }}
                        className="btn btn-primary btn-block mt-3"
                      >
                        {isLoading ? (
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : (
                          "LOGIN"
                        )}
                      </button>
                      <div className="text-center w-100 mt-3">
                        <button
                          type="button"
                          onClick={handleLogin}
                          style={{
                            height: "55px",
                            borderRadius: "50px",
                            maxWidth: "100%",
                          }}
                          className="firebaseui-idp-button text-center bg-transparent shadow-none border mdl-button w-100 mdl-js-button mdl-button--raised firebaseui-idp-google firebaseui-id-idp-button"
                          data-provider-id="google.com"
                          data-upgraded=",MaterialButton"
                        >
                          <span className="firebaseui-idp-icon-wrapper">
                            <img
                              className="firebaseui-idp-icon"
                              src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                            />
                          </span>
                          <span className="firebaseui-idp-text firebaseui-idp-text-long text-uppercase">
                            Sign in with Google
                          </span>
                          <span className="firebaseui-idp-text firebaseui-idp-text-short text-uppercase">
                            Google
                          </span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};
