import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Views/login";
import Home from "./Views/home";
import Layout from "./layout";
import Profile from "./Views/profile";
import AlertWrapper from "./Components/Alert/AlertWrapper";
import Alert from "./Components/Alert/Alert";
import { useState } from "react";
import PrivateRoute from "./privateRoute";
import Genres from "./Views/genres";
import BookDetail from "./Views/bookDetail";
import { BooksProvider } from "./Hooks/useBook";
import SignUp from "./Views/signup";
import LandingPage from "./Views/LandingPage";
import { Helmet } from "react-helmet";
function App() {
  const [successMessage, setSuccessMessage] = useState("");

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Directorize</title>
        <meta
          name="description"
          content="Directorize is an exclusive online textbook directory for ASD students, offering easy access to all your school textbooks in one place. Browse by category, view key details, and read instantly with a seamless and secure experience tailored just for you."
        />
        <link rel="canonical" href="https://directorize.education/" />
        <link
          id="favicon"
          rel="icon"
          type="image/svg"
          href="/favicon.svg"
          sizes="16x16"
          data-react-helmet="true"
        />
        <title>Directorize</title>
        <meta property="og:title" content="Directorize" />
        <meta
          property="og:description"
          content="Directorize is an exclusive online textbook directory for ASD students, offering easy access to all your school textbooks in one place. Browse by category, view key details, and read instantly with a seamless and secure experience tailored just for you."
        />
        <meta
          property="og:image"
          content="https://directorize.education/favicon.png"
        />
        <meta property="og:url" content="https://directorize.education/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Directorize" />
        <meta
          name="twitter:description"
          content="Find and access educational resources easily with Directorize."
        />
        <meta
          name="twitter:image"
          content="https://directorize.education/directorize-blue-logo.svg"
        />
      </Helmet>
      <AlertWrapper>
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
      </AlertWrapper>
      <Router>
        <Routes>
          <Route path="/" exact element={<LandingPage />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/signup" exact element={<SignUp />} />

          <Route
            path="/*"
            element={
              <PrivateRoute>
                <BooksProvider>
                  <Layout />
                </BooksProvider>
              </PrivateRoute>
            }
          >
            <Route
              path="home"
              element={
                <PrivateRoute>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route
              path="genres"
              element={
                <PrivateRoute>
                  <Genres />
                </PrivateRoute>
              }
            />
            <Route
              path="book/:id"
              element={
                <PrivateRoute>
                  <BookDetail />
                </PrivateRoute>
              }
            />
            <Route
              path="profile"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
