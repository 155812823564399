import React, { createContext, useState } from "react";
import BooksData from "../books.json";

// Create a Context
export const BooksContext = createContext();

// Create a Provider Component
export const BooksProvider = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredBooks, setFilteredBooks] = useState(BooksData);

  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    if (term === "") {
      setFilteredBooks(BooksData);
    } else {
      const filtered = BooksData?.filter((book) =>
        book?.title?.toLowerCase().includes(term?.toLowerCase())
      );
      setFilteredBooks(filtered);
    }
  };

  return (
    <BooksContext.Provider value={{ searchTerm, filteredBooks, handleSearch }}>
      {children}
    </BooksContext.Provider>
  );
};
