import React from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../Hooks/useAuth";

export const LandingPageComponent = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <>
      <div className="p-4 pb-5 bg-white">
        <div className="d-flex d-block align-items-center justify-content-between">
          <div>
            <img src="/directorize-blue-logo.svg" alt="logo" className="w-50" />
          </div>
          <div className="landing-page-buttons mt-md-0 text-md-right text-center d-flex align-items-center">
            {user?.uid ? (
              <button
                className="mr-3"
                style={{ whiteSpace: "nowrap" }}
                onClick={() => navigate("/home")}
              >
                Dashboard
              </button>
            ) : (
              <>
                <button
                  className="mr-3"
                  style={{ whiteSpace: "nowrap" }}
                  onClick={() => navigate("/signup")}
                >
                  Sign Up
                </button>
                <button onClick={() => navigate("/login")}>Login</button>
              </>
            )}
          </div>
        </div>
        <div className="text-center banner-section my-5 py-md-5 py-0 pt-5 d-flex flex-column align-items-center justify-content-center">
          <h1 className="font-weight-bold">
            We Make Studying Clear and Simple
          </h1>
          <p className="mt-4">
            Access, organize, and study your textbooks with Directorize, the
            platform dedicated to every ASD student at every level.
          </p>
          <button
            className="btn-guardian mt-md-5 mt-3"
            onClick={() => navigate("/signup")}
          >
            Get Started
          </button>
        </div>

        <div className="container">
          <div className="row user-info my-md-5 mt-0">
            <div className="col-lg-4 px-md-2 px-3 mt-lg-0 mt-3">
              <div
                className="card bg-primary ml-0 mr-0 w-100"
                style={{ borderRadius: 25 }}
              >
                <div className="card-body">
                  <img src="/textbook.png" alt="" />
                  <div>
                    <h2 className="text-primary font-weight-bold mt-2">
                      Textbook Directory
                    </h2>
                    <p className="text-secondary mt-3 fs-18 px-2">
                      Browse and organize all your textbooks by category for
                      easy access and efficient study sessions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 px-md-2 px-3 mt-lg-0 mt-3">
              <div
                className="card bg-primary ml-0 mr-0 w-100"
                style={{ borderRadius: 25 }}
              >
                <div className="card-body">
                  <img src="/asd_student.png" alt="" />
                  <div>
                    <h2 className="text-primary font-weight-bold mt-2">
                      ASD Students Only
                    </h2>
                    <p className="text-secondary mt-3 fs-18 px-2">
                      Exclusive access for American School of Dubai students
                      ensures a personalized and secure learning environment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 px-md-2 px-3 mt-lg-0 mt-3">
              <div
                className="card bg-primary ml-0 mr-0 w-100"
                style={{ borderRadius: 25 }}
              >
                <div className="card-body">
                  <img src="/instant.png" alt="" />
                  <div>
                    <h2 className="text-primary font-weight-bold mt-3">
                      Instant Access
                    </h2>
                    <p className="text-secondary mt-3 fs-18 px-2">
                      Access your textbooks instantly with just one click,
                      anytime and anywhere, for uninterrupted learning.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-grenadian">
        <div className="py-5 container">
          <div className="row mx-0">
            <div className="col-md-4">
              <div>
                <img src="/directorize-white-logo.svg" width={300} alt="" />
                <p className="text-white fs-13 mt-4">
                  Directorize makes it incredibly easy for ASD students to
                  access and organize their textbooks online. Browse, read, and
                  study from anywhere with just a few clicks.
                </p>
                <p className="text-white fs-13">
                  Sign up to stay updated on the latest features, tips, and
                  exclusive content from Directorize.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <ul className="ml-xl-5 ml-0 pl-0 mt-5">
                <li className="nav-link text-white mt-4 font-weight-bold">
                  Company
                </li>
                <li className="nav-link text-white mt-4">About</li>
                <li className="nav-link text-white mt-4">Instagram</li>
                <li className="nav-link text-white mt-4">Twitter</li>
                <li className="nav-link text-white mt-4">News</li>
                <l className="nav-link text-white mt-4" i>
                  Merch
                </l>
              </ul>
            </div>
            <div className="col-md-4 text-white mt-5">
              <h5>Privacy Policy and Terms of Use</h5>
              <p className="mt-3"> Directorize Privacy Policy</p>
              <p> Directorize Terms of Use</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
