/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BooksContext } from "../Hooks/useBook";

const Sidebar = () => {
  const { handleSearch } = useContext(BooksContext);

  const location = useLocation();

  // Update the URL search parameter
  const params = new URLSearchParams(location.search);
  const term = params.get("search") || "";
  const navigate = useNavigate();
  return (
    <div className="d-md-block d-none">
      <nav className="sidebar pl-0">
        <ul>
          <li>
            <span
              href="#"
              className="font-weight-bold text-white fs-20 pl-4"
              style={{ cursor: "default" }}
            >
              Textbook by Genre
            </span>
          </li>
          <li
            className={`${term === "all" ? "active" : ""}`}
            onClick={() => {
              handleSearch({ target: { value: "" } });
              params.set("search", "all");
            }}
          >
            <Link to={"/genres"}>All</Link>
          </li>
          <li
            className={`${term === "english" ? "active" : ""}`}
            onClick={() => {
              const data = {
                target: {
                  value: "english",
                },
              };
              handleSearch(data);
              params.set("search", "english");
              navigate({ pathname: "/genres", search: params.toString() });
            }}
          >
            <Link to="/genres"> English</Link>
          </li>
          <li
            className={`${term === "math" ? "active" : ""}`}
            onClick={() => {
              handleSearch({
                target: {
                  value: "math",
                },
              });
              params.set("search", "math");
              navigate({ pathname: "/genres", search: params.toString() });
            }}
          >
            <Link to="/genres"> Math</Link>
          </li>
          <li
            className={`${term === "economics" ? "active" : ""}`}
            onClick={() => {
              handleSearch({
                target: {
                  value: "economics",
                },
              });
              params.set("search", "economics");
              navigate({ pathname: "/genres", search: params.toString() });
            }}
          >
            <Link to="/genres"> Economics</Link>
          </li>
          <li
            className={`${term === "biology" ? "active" : ""}`}
            onClick={() => {
              handleSearch({
                target: {
                  value: "biology",
                },
              });
              params.set("search", "biology");
              navigate({ pathname: "/genres", search: params.toString() });
            }}
          >
            <Link to="/genres"> Biology</Link>
          </li>
          <li
            className={`${term === "physics" ? "active" : ""}`}
            onClick={() => {
              handleSearch({
                target: {
                  value: "physics",
                },
              });
              params.set("search", "physics");
              navigate({ pathname: "/genres", search: params.toString() });
            }}
          >
            <Link to="/genres"> Physics</Link>
          </li>
          <li
            className={`${term === "chemistry" ? "active" : ""}`}
            onClick={() => {
              handleSearch({
                target: {
                  value: "chemistry",
                },
              });
              params.set("search", "chemistry");
              navigate({ pathname: "/genres", search: params.toString() });
            }}
          >
            <Link to="/genres"> Chemistry </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
